body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(90deg, rgba(2,180,228,1) 18%, rgba(8,138,175,1) 47%, rgba(28,0,3,1) 100%);
}

.app {
  display: flex;
}

.dashb {
  width: 77%;
    margin-left: 20%;
}

.main-content {
  width: 100%;
  height: 100%;
  padding-left: 0px;
}@media (max-width: 1240px) {
  .dashb {
    width: 72%;
      margin-left: 26%;
  }
  .sidebar.active {
    transform: translateX(0); 
  }

  .main-content {
    padding-left: 20px;
  }
}
@media (max-width: 1024px) {
  .dashb {
    width: 72%;
      margin-left: 26%;
  }
  .sidebar.active {
    transform: translateX(0); 
  }

  .main-content {
    padding-left: 20px;
  }
}
@media (max-width: 968px) {
  .dashb {
    width: 70%;
      margin-left: 29%;
  }
  .sidebar.active {
    transform: translateX(0); 
  }

  .main-content {
    padding-left: 20px;
  }
}
@media (max-width: 876px) {
  .dashb {
    width: 65%;
      margin-left: 32%;
  }
  .sidebar.active {
    transform: translateX(0); 
  }

  .main-content {
    padding-left: 20px;
  }
}
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    transform: translateX(-100%); 
    z-index: 1000;
  }

  .sidebar.active {
    transform: translateX(0); 
  }

  .main-content {
    padding-left: 20px;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 200px; 
  }

  .main-content {
    padding: 10px; 
  }
}