/* Support.css */
.card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  .mas {
    margin-top: -4%;
}



/* ChatBox.css */
.chatbox-container {
  border: 1px solid #ddd;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 700px;
  width: 65%;

  background-color: white;
  border-radius: 5px;
  width: 400px; 
  max-width: 90%; 
}

.chatbox-header {
  background-color: #25d366; /* WhatsApp-like color */
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.chatbox-messages {
  max-height: 600px;
  flex-grow: 1;
  padding: 10px;
  background-color: #f1f1f1;
  overflow-y: auto;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%;
  position: relative;
  word-wrap: break-word; /* Ensures long words break properly */
}

.sent {
  background-color: #dcf8c6; /* Color for sent messages */
  margin-left: auto; /* Align to the right */
}

.received {
  background-color: #ffffff; /* Color for received messages */
}

.message-time {
  font-size: 0.75rem; /* Smaller font for timestamp */
  color: #999;
  text-align: right; /* Align text to the right */
  margin-top: 5px; /* Space above timestamp */
}

.chatbox-input {
  display: flex;
  padding: 10px;
  background-color: white; /* Background color for input section */
}

.chatbox-input input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.chatbox-input button {
  background-color: #25d366; /* WhatsApp-like color */
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
}
.chatbox-overlay {
  position: fixed; /* Makes the chatbox overlay fixed */
  top: 0;
  left: 180px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

