.order-updates__container {
    margin-top: 5%;
  }
  
  .order-updates__card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .order-update__card {
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    border-radius: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .order-update__card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .order-update__card .progress-bar {
    height: 18px;
    border-radius: 10px;
    font-weight: bold;
  }
  
  .order-updates__recent-activity h4 {
    margin-bottom: 20px;
  }
  
  .order-updates__recent-activity .list-group-item {
    background-color: #f8f9fa;
    border-left: 5px solid #007bff;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .order-updates__recent-activity .list-group-item:hover {
    background-color: #e2e6ea;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .order-updates__card-wrapper {
      grid-template-columns: 1fr; /* Single column for medium screens */
    }
  }
  
  @media (max-width: 576px) {
    .order-updates__card-wrapper {
      grid-template-columns: 1fr; /* Single column for small screens */
      padding: 0 10px; /* Add padding to avoid edges */
    }
  
    .order-update__card {
      margin: 0 auto; /* Center cards */
    }
  
    .text11 {
      text-align: center; /* Center-align the title for small screens */
      margin: 20% 0; /* Adjust margin for better appearance */
    }
  
    .order-updates__recent-activity {
      margin: 0 auto; /* Center-align recent activity section */
    }
  }
  