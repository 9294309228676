.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff; /* Adjust background color as needed */
}

.loader .text-container {
  position: absolute;
  text-align: center;
  color: #000; /* Adjust text color as needed */
}

.loader .animated-text {
  font-size: 2rem;
  font-weight: bold;
}

.inline-block {
  display: inline-block;
}

.absolute {
  position: absolute;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}


/*  main css */

/* Adjust navbar padding */
.navbar {
  padding: 1rem 1rem;
}

/* Adjust logo size */
.navbar-brand img {
  max-width: 100px;
  height: auto;
}

/* Space between nav items */
.nav-link {
  margin-left: 1rem;
  margin-right: 1rem;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

/* Align items to the right */
.navbar-nav {
  margin-left: auto;
}

/* Remove default border on toggler */
.navbar-toggler {
  border: none;
}

/* Hover color for nav items */
.nav-link:hover {
  color: #007bff; /* Blue color for hover effect */
}
.pfu {
  font-size: 100%;
}
.vect {
  width: 80%; /* Adjust image size */
  margin-top: 50px;
  margin-left: 0;
  margin-right: 0;
}
.IMGMAIN{
  /* border: solid; */
  height: auto;
 }
/* Responsive adjustments */
@media (max-width: 600px) {
  .navbar-nav {
    margin-top: 0.5rem; 
  }
 
  
}
  /* animated text  vect */
  
  .animate-charcter {
    font-size: calc(16px + 2vw); /* Responsive font size */
    text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #f7ea00 5%,
      #c714db 29%,
      #de0707 67%,
      #65f35b 100%
    );
    background-size: 60% auto;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite;
    font-family: 'Times New Roman', Times, serif;
    margin: 0 auto; /* Centering */
    width: max-content;
  }
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
  .print-vect{
    width: 100%;
    display: flex;
    justify-content: space-between;
  
  }
  .print-row{
    /* border: solid; */
    padding-top: 250px;
  } 

  @media only screen and (max-width: 600px) {
  .home {
    padding: 20px;
  }

  .print-vect {
    flex-direction: column;
    align-items: center;
  }

  

  .vect {
    width: 100%; /* Adjust image size */
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0p;
    /* border: 2px solid black; */
  }
  .print-row{
    /* border: solid; */
    padding-top: 72px;
  }  
}

/* ..........about.... */

.animated-section {
  background-image: url('../../img/background-photo/coresol.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.animated-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adds a dark overlay */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  color: white;
  animation: fadeInUp 7s ease-in-out;
  text-align: justify;

  max-width: 800px; /* Max width for larger screens */
  margin: 0 auto; /* Center content */
}

.welcome {
  text-align: center;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .animated-section {
    height: auto; /* Adjust height for smaller screens */
    padding: 2rem 0; /* Add padding to the top and bottom */
  }
  
  .content {
    width: 90%; /* Slightly smaller width for small screens */
    padding: 1rem; /* Add padding inside content */
  }
  
  h1 {
    font-size: 1.5rem; /* Smaller font size for headings */
  }
  
  p {
    font-size: 0.9rem; /* Smaller font size for paragraphs */
  }
}

/* ,,,,,,,,, service section.....*/

  /* portfolio */
  
  .portfolio {
    background-color: hsl(0, 0%, 0%);
    color: white; /* Ensure text is visible on the dark background */
    padding: 50px 0;
  }
  
  .portfolio .btn {
    --color: #ffffff;
    padding: 0.8em 1.7em;
    background-color: transparent;
    border-radius: .3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 400;
    font-size: 17px;
    border: 1px solid;
    font-family: inherit;
    text-transform: uppercase;
    color: var(--color);
    z-index: 1;
  }
  
  .portfolio .btn::before, .btn::after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: 1s ease;
  }
  
  .portfolio .btn::before {
    top: -1em;
    left: -1em;
  }
  
  .portfolio .btn::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
  }
  
  .portfolio .btn:hover::before, .btn:hover::after {
    height: 410px;
    width: 410px;
  }
  
  .portfolio .btn:hover {
    color: white;
    background: linear-gradient(45deg, #073104, #efff0b); /* Default gradient, will be overridden */
  }
  
  .portfolio .btn.visiting-cards:hover {
    background: linear-gradient(45deg, #f5e722, #88fd3f); /* Specific gradient for Visiting Cards */
  }
  
  .portfolio .btn.invitation-cards:hover {
    background: linear-gradient(45deg, #ae1e79, #cad754); /* Specific gradient for Invitation Cards */
  }
  
  .portfolio .btn.id-cards:hover {
    background: linear-gradient(45deg, #6cff0a, #ee2a26); /* Specific gradient for ID Cards */
  }
  
  .portfolio .btn.led-frames:hover {
    background: linear-gradient(45deg, #fff308, #ff0b81); /* Specific gradient for Led Frames */
  }
  
  .portfolio .btn.flex:hover {
    background: linear-gradient(45deg, #ff1f5b, #ccf500); /* Specific gradient for Flex */
  }
  
  .portfolio .btn.momentos:hover {
    background: linear-gradient(45deg, #f6f629, #ef301b); /* Specific gradient for Momentos */
  }
  
  .portfolio .btn.roll-up-banner:hover {
    background: linear-gradient(45deg, #2df629, #eeff02); /* Specific gradient for Roll up Banner */
  }
  
  .portfolio .btn:active {
    filter: brightness(.8);
  }
  
  
  .port-container {
    padding: 0 15px; /* Add padding for better spacing */
  }
  
  .img-row {
    display: flex;
    flex-wrap: wrap; /* Wrap images on smaller screens */
    justify-content: center; /* Center the images */
  }
  
  .img-row .col-6,
  .img-row .col-md-4,
  .img-row .col-lg-3 {
    padding: 0; /* Remove padding for image columns */
  }
  
  .img-row img {
    object-fit: cover;
    width: 70%;
    height: auto;
  }
  .img-row img:hover {
    background-color: #00BFFF;
    transform: scale(0.2s); /* Zoom effect */

  }
  /* Container styling for the zoom effect */
.zoom-effect {
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  /* Optional: Adds border-radius for rounded corners */
  border-radius: 10px;
}

.zoom-effect:hover {
  transform: scale(1.1); /* Zoom-in effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .portfolio h1 {
      font-size: 1.8rem; /* Adjust title size for smaller screens */
    }
    
    .img-row .col-6 {
      width: 100%; /* Full-width images on small screens */
    }
    
    .btn {
      padding: 10px; /* Adjust button padding for small screens */
      font-size: 0.9rem; /* Adjust font size on buttons */
    }
    .service-detail-box {
       width: 242px;      /* service-box-media */
    }
    .service-box{
      margin: 15px 60px;

    }
  }
  
  /* contact */
#contact {
  background-color: black;
  color: #ffffff; /* Set text color for contrast */
  padding: 40px 20px; /* Adjust padding as needed */
}

.CONTACT-container {
  width: 100%;
  max-width: 800px; /* Adjust the max width for larger screens */
  margin: 0 auto; /* Center align the container */
}

.text-blue {
  color: #ffffff; /* Blue color for text */
}

.btn-skyblue {
  background-color: #87beeb; /* Sky blue color */
  color: black; /* Text color for better readability */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Adjust padding */
}

.btn-skyblue:hover {
  background-color: #00BFFF; /* Slightly darker sky blue on hover */
}

@media (max-width: 600px) {
  .CONTACT-container {
    width: 90%; /* Adjust container width for smaller screens */
  }

  .form-control {
    font-size: 1rem; /* Adjust font size for better readability on small screens */
  }

  .btn-skyblue {
    width: 100%; /* Full-width button on smaller screens */
  }
}


/* footer  */
/* Base styles */
.footer-container {
  padding-top: 40px;
  padding-left: 100px;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  flex: 1 1 0px;
  margin-bottom: 1rem;
}

.footer-logo img {
  width: 100px;
}

.footer-quick-links, .footer-additional-links, .footer-contact {
  margin-bottom: 1rem;
}

.footer-quick-links ul, .footer-additional-links ul {
  padding-left: 100px;
}

.footer-contact ul {
  list-style: none;
  padding-left: 0;
}

.footer-social-icons a {
  color: black;
  margin-right: 1rem;
}

/* Media query for 600px */
@media (max-width: 600px) {
  .footer-container {
    padding-left: 15px;
  }

  .footer-row {
    flex-direction: column;
    align-items: center;
  }

  .footer-quick-links ul, .footer-additional-links ul {
    padding-left: 0;
  }

  .footer-logo img {
    width: 80px;
  }

  .footer-social-icons {
    text-align: center;
    margin-top: 10px;
  }

  .footer-social-icons a {
    margin-right: 0.5rem;
  }
}

/* top  button */
.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(232, 115, 115);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: 20px;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: rgb(0, 0, 0);
}

.button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(245, 232, 61);
  align-items: center;
}

.button:hover .svgIcon {
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.button::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: rgb(0, 0, 0);
  font-size: 0px;
}

.button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  transition-duration: 0.3s;
}

/* Responsive styles */
@media (max-width: 600px) {
  .button {
    width: 40px;
    height: 40px;
  }

  .svgIcon {
    width: 10px;
  }

  .button:hover {
    width: 120px;
  }

  .button::before {
    font-size: 0px;
  }

  .button:hover::before {
    font-size: 11px;
  }
}

/*............ text print u................. */


.absolute {
  position: absolute;
}

.inline-block {
  display: inline-block;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  margin: 0;
  position: relative;
  font-size: 70px;
}

.w-2 {
  width: 0.5em;
}

.dash {
  animation: dashArray 2s ease-in-out infinite, dashOffset 2s linear infinite;
}

.spin {
  animation: spinDashArray 2s ease-in-out infinite,
    spin 8s ease-in-out infinite,
    dashOffset 2s linear infinite;
  transform-origin: center;
}

@keyframes dashArray {
  0% {
    stroke-dasharray: 0 1 359 0;
  }

  50% {
    stroke-dasharray: 0 359 1 0;
  }

  100% {
    stroke-dasharray: 359 1 0 0;
  }
}

@keyframes spinDashArray {
  0% {
    stroke-dasharray: 270 90;
  }

  50% {
    stroke-dasharray: 0 360;
  }

  100% {
    stroke-dasharray: 270 90;
  }
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: 365;
  }

  100% {
    stroke-dashoffset: 5;
  }
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }

  12.5%,
  25% {
    rotate: 270deg;
  }

  37.5%,
  50% {
    rotate: 540deg;
  }

  62.5%,
  75% {
    rotate: 810deg;
  }

  87.5%,
  100% {
    rotate: 1080deg;
  }
}

.text-container {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
}

.animated-text {
  color: #ed2b1d; /* Or any other color */
  font-size: 2em;
  animation: zoomOutIn 3s ease-in-out forwards;
}

@keyframes zoomOutIn {
  0% {
    transform: scale(1); /* Starting size */
  }
  25% {
    transform: scale(0.5); /* Zoom out */
  }
  50% {
    transform: scale(0.5); /* Hold zoom out */
  }
  100% {
    transform: scale(1); /* Zoom in to original size */
  }
}

/* animated text & vector */
  .home1 {
    position: relative;
    padding-top: 50px; /* Ensure there's space for the scrolling text */
  }

  .scrolling-text-box {
    position: absolute;
    top: 80px; /* Place it slightly down from the top */
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 10px 0;
    overflow: hidden;
    z-index: 1; /* Ensure it's above other content */
  }

  .scrolling-text-box p {
    display: inline-block;
    white-space: nowrap;
    animation: scrollText 10s linear infinite;
    font-size: 1.2rem;
    margin: 0;
  }

  /* Keyframes for scrolling animation */
  @keyframes scrollText {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }