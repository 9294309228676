.page-container {
    animation: fadeIn 0.5s ease forwards;
  }
  
  .design-card {
    height: 100%;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .design-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  }
  
  .design-image {
    max-width: 90%;
    height: 100%;
    align-self: center;
    transition: transform 0.3s ease;
  }
  
  .design-card:hover .design-image {
    transform: scale(1.05);
  }
  
  .card-body {
    padding: 15px;
  }
  
  .card-body h5 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  
  .card-body p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .card-footer {
    background-color: #f8f9fa;
    padding: 10px;
  }
  
  .btn {
    transition: background-color 0.3s ease;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in {
    animation: fadeIn 0.5s ease forwards;
  }
  
  /* Responsive Design Adjustments */
  @media (max-width: 768px) {
    .design-card {
      margin-bottom: 20px;
      width: 100%; /* Full width for medium-sized screens */
    }
  }
  
  @media (max-width: 576px) {
    .design-card {
      margin-bottom: 15px;
      width: 100%; /* No excessive width */
      margin: 0 auto; /* Centered on small screens */
    }
  
    .savet {
      margin-left: 0; /* Remove negative margin */
      font-size: 1.5rem; /* Adjust heading font size */
    }
  
    .card-body h5 {
      font-size: 1rem; /* Adjust title font size for small screens */
    }
  
    .card-body p {
      font-size: 0.85rem; /* Adjust text size for readability */
    }
  
    .card-footer {
      padding: 8px; /* Reduce footer padding for small screens */
    }
  
    .btn {
      padding: 8px 12px; /* Adjust button padding */
    }
  }
  