/* Page container */
.upload-design-page {
    padding: 50px 0;
    animation: fadeIn 0.5s ease forwards;
  }
  
  /* Upload card with hover effect */
  .upload-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .upload-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
  }
  
  /* Form controls */
  .upload-design-form .form-control {
    border-radius: 8px;
    font-size: 1.1rem;
    padding: 12px;
  }
  
  .upload-design-form label {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }
  
  /* Button styles */
  .upload-btn {
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .upload-btn:hover {
    background-color: #0056b3;
  }
  
  /* Shadow effect */
  .shadow-sm {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .upload-card {
      padding: 40px 20px;
    }
  }
  
  @media (max-width: 576px) {
    .upload-design-page {
      padding: 30px 10px;
      margin-top: 7%;
    }
  
    .upload-card {
      width: 100%; /* Ensure the card takes the full width of the screen */
      margin: 0 auto; /* Center the card */
    }
  
    .upload-btn {
      font-size: 1rem;
      padding: 8px 16px;
    }
  }
  