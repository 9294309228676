@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(90deg, rgba(2,180,228,1) 18%, rgba(8,138,175,1) 47%, rgba(28,0,3,1) 100%);
}

.login-box {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 400px; /* Set a max-width for better responsiveness */
}

.login-box h2 {
  font-size: 24px;
}

.form-group {
  position: relative;
}

.form-control {
  padding: 10px 12px; /* Adjust padding */
  font-size: 16px;
}

.btn-primary {
  font-size: 18px;
}

.alert {
  margin-top: 10px; /* Add some space above error message */
  border-radius: 8px; /* Rounded corners */
}

/* Responsive styles */
@media (max-width: 576px) {
  .login-box {
    width: 90%; /* Adjust width for small screens */
    margin: 0 auto; /* Center the box */
  }
}

.login-box:hover {
  transform: translateY(-5px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-box {
  animation: fadeIn 1s ease-in-out;
}
