.notifications-container {
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
  }
  
  .notifications-title {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .notifications-list {
    list-style-type: none;
    padding: 0;
  }
  
  .notification-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .notification-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .notification-header h3 {
    font-size: 1.25em;
    margin: 0;
  }
  
  .notification-time {
    font-size: 0.85em;
    color: #999;
  }
  
  .notification-card p {
    font-size: 1em;
    color: #333;
  }
  
  /* Specific card types for better visual distinction */
  .order {
    border-left: 5px solid #ff9800;
  }
  
  .approval {
    border-left: 5px solid #4caf50;
  }
  
  .payment {
    border-left: 5px solid #2196f3;
  }
  
  .shipping {
    border-left: 5px solid #9c27b0;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .notifications-container {
      padding: 15px;
    }
  
    .notifications-title {
      font-size: 1.5em;
    }
  
    .notification-card {
      padding: 15px;
    }
  
    .notification-header h3 {
      font-size: 1.1em;
    }
  }
  
  @media (max-width: 576px) {
    .notifications-container {
      padding: 10px;
      margin: 0; /* Remove negative margins */
      width: 100%; /* Full width on small screens */
    }
  
    .notifications-title {
      font-size: 1.25em; /* Adjust title font size */
    }
  
    .notification-card {
      padding: 12px; /* Reduce padding on small screens */
    }
  
    .notification-header h3 {
      font-size: 1em; /* Adjust header size */
    }
  
    .notification-time {
      font-size: 0.8em; /* Smaller time text */
    }
  
    .notification-card p {
      font-size: 0.9em; /* Smaller text for descriptions */
    }
  }
  