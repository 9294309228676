/* Profile Header */
.profile-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 3px solid #007bff;
    transition: transform 0.3s ease-in-out;
  }
  
  .profile-image:hover {
    transform: scale(1.1);
    border-color: #28a745;
  }
  .detail1 {
    margin-top: -5%;
    margin-bottom: -4%;
  }
  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .profile-header h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .profile-header p {
    font-size: 1rem;
  }
  
  .profile-card {
    max-width: 350px;
    width: 100%;
    background-color: #7b050b;
    border-radius: 15px;
    color: white;
  }
  
  /* Quick Access Cards */
  .quick-access {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .quick-access:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  }
  
  /* Creative Page */
  .creative-page {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 15px;
  }
  
  .designs-grid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .design-box {
    width: 150px;
    height: 150px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .design-box:hover {
    transform: scale(1.05);
    background-color: #007bff;
    color: white;
  }
  
  /* Animations */
  .fade-in {
    animation: fadeIn 0.5s ease-in;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (max-width: 767px) {
    .dashh {
      font-size: 1.5em;
      margin-left: 20%;
    }
  }
  @media (max-width: 600px) {
    .dashh {
      font-size: 1.3em;
      margin-left: 20%;
    }
  }
  @media (max-width: 400px) {
    .dashh {
      font-size: 1em;
      margin-left: 20%;
    }
  }