
.our-orders-container {
  overflow: hidden;
    animation: fadeIn 0.8s ease forwards;
  }
  
  .order-card {
    border-radius: 15px;
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    cursor: pointer;
    position: relative;
  }

  .our-orders-col{
    justify-self: right;
  }
  
  .order-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
  }
  
  .order-header {
    display: block;
    margin-bottom: 15px;
  }
  
  .status {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    color: #fff;
    animation: pulse 1s infinite;
  }
  
  .status.shipped {
    background-color: #28a745;
  }
  
  .status.processing {
    background-color: #ffc107;
  }
  
  .status.delivered {
    background-color: #007bff;
  }
  
  .status.cancelled {
    background-color: #dc3545;
  }
  
  /* Animation for loading cards */
  .order-animate {
    opacity: 0;
    transform: translateY(30px);
  }
  /* Status pulse effect */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Fade-in for page */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in {
    animation: fadeIn 0.8s ease forwards;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .order-card {
      transform: none;
      transition: transform 0.2s, box-shadow 0.2s;
    }
  
    .order-card:hover {
      transform: none;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    }
  
    .status {
      font-size: 0.8rem;
      padding: 0.4rem 0.8rem;
    }
  
    .order-header h5 {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 576px) {
    .order-card {
      margin-bottom: 1.5rem;
      width: 100%;
    }
  
    .order-header h5 {
      font-size: 1rem;
    }
  
    .our-orders-container {
      padding: 0 15px;
    }
  
    .order-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .status {
      margin-top: 10px;
    }
  }
  