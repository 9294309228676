	
/* Container with gradient background and centered content */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 20px;
  background: linear-gradient(135deg, #ff4b2b, #ff416c, #ff4b2b, #ffcc00, #007bff);
  background-size: 300% 300%;
  animation: gradientShift 10s ease infinite;
}

/* Animation for gradient background shift */
@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Form styling with animated entrance and box-shadow */
.login-form {
  background: #fff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease;
  width: 100%;
  max-width: 400px;
}

/* Entrance animation for the form */
@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Form header styling */
.login-form h2 {
  margin-bottom: 25px;
  text-align: center;
  color: #333;
}

/* Form group layout */
.form-group {
  margin-bottom: 20px;
}

/* Label styling */
.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

/* Input field styling with focus effect */
.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #ff4b2b;
  outline: none;
}

/* Password field container styling */
.password-container {
  display: flex;
  align-items: center;
}

/* Password toggle button styling */
.password-toggle {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-left: 10px;
}

/* Error message styling */
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Button styling with hover and focus effects */
.login-button {
  width: 100%;
  padding: 12px;
  background-color: #ff4b2b;
  border: none;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-button:hover {
  background-color: #ff416c;
  transform: scale(1.05);
}

.login-button:focus {
  outline: none;
  box-shadow: 0 0 10px #ff416c;
}

/* Toggle link styling with hover effect */
.toggle-link {
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s;
}

.toggle-link:hover {
  color: #ff4b2b;
  text-decoration: underline;
}

/* Responsive styles for screens 600px or less */
@media screen and (max-width: 600px) {
  .login-form {
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
  }

  .login-form h2 {
    font-size: 1.5rem;
  }

  .form-group input {
    padding: 8px;
  }

  .login-button {
    padding: 10px;
    font-size: 1rem;
  }

  .toggle-link {
    font-size: 0.9rem;
  }
}
