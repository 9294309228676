.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Glassmorphism modal */
  .modal-content {
    background: rgba(255, 255, 255, 0.15); /* Semi-transparent for glass effect */
    backdrop-filter: blur(10px); /* Soft blur for glassmorphism */
    padding: 30px;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    position: relative;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    transform: translateY(100px);
    opacity: 0;
    animation: slideIn 0.5s ease forwards;
  }
  
  /* Form title */
  .form-title {
    font-size: 28px;
    color: #ffffff;
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Form Body */
  .form-body {
    margin-top: 20px;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  .formModel-form-control {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 25px;
    padding: 15px;
    color: white;
    width: 100%;
    font-size: 16px;
    outline: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .formModel-form-control::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  /* Focus Effect */
  .formModel-form-control:focus {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
    transform: scale(1.02);
  }
  
  /* Button styling */
  .btn {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    color: white;
    padding: 15px 30px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.3s ease;
  }
  
  .btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .close-btn {
    background-color: transparent;
    border: none;
    color: #ff4f4f;
    font-size: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-btn:hover {
    color: #ff1a1a;
  }
  
  /* Animations */
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }