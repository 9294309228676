/* Sidebar styles */
.sidebar {
    width: 250px;
    height: 111vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    z-index: 1040;
}

/* Dark mode styles */
.sidebar.dark {
    background: #1e1e2d;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.8);
}

/* Profile section */
.profile-card {
    padding: 15px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
    border-radius: 15px;
    text-align: center;
}

.profile-img {
    width: 100px; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
    /* border-radius: 50%; */
    background-color: #000;
    margin: 0 auto; /* Center the image */
    display: block;
  }
  
.profile-name {
    font-size: 1.5em;
    margin-top: 5%;
    color: #333;
}

/* Menu section */
.menu-section a {
    display: flex;
    align-items: center;
    padding: 10px 0;
    color: inherit; /* Maintain text color */
  }
  
  .menu-section a i {
    margin-right: 10px; /* Space between icon and text */
    font-size: 1.3rem; /* Adjust icon size */
  }  

.menu-section ul li {
    margin: 15px 0;
    font-size: 1.2em;
}

.menu-section ul li a {
    font-size: 80%;
    text-decoration: none;
    padding: 10px 20px;
    display: block;
    border-radius: 10px;
    background-color: lightcyan;
    transition: all 0.3s ease;
}

.menu-section ul li a:hover {
    background: #f0f0f0;
    color: #000;
}

/* Settings section */
.dark-mode-toggle {
    padding: 10px 20px;
    background-color: #6c63ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dark-mode-toggle:hover {
    background-color: #5a54e8;
}

/* Offcanvas */
.offcanvas.dark {
    background: #1e1e2d;
}

/* Hamburger button */
.sidebar-toggle {
    position: fixed;
    top: 15px;
    left: 14px;
    background-color: #67e0d8;
    border-radius: 5px;
    z-index: 1050;
}
