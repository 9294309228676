/* ,,,,,,,,, service section.....*/

  
  /* Animated background image */
  #services {
    background: url('../../img/portfolio/1\ to\ 4\ -1.jpg') no-repeat center center fixed;
    background-size: cover;
    animation: backgroundAnimation 30s ease infinite;
    position: relative;
    overflow: hidden;
  }
  
  @keyframes backgroundAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  /* Dark overlay */
  #services::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  
  .services {
    padding: 50px 0;
  }
  
  .service-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .service-box {
    background-color:  hsl(0, 0%, 100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: black;
    padding: 20px;
    margin: 15px 100px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    border-radius: 10px;
  }
  .service-box:hover {
    background-color: #ecfd2e;
    color: black;
  }
  
  .service-background img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  

  /* service hidden box code */

  .service-box {
    cursor: pointer;
  }
  .service-detail-overlay {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center; 
    align-items: center; 
    z-index: 1000;
    overflow: hidden; 
}
  
.service-detail-box {
    background: hsl(0, 87%, 65%);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 90%; 
    max-height: 90%; 
    overflow: hidden; 
    width: 300px;
    position: relative; 
}
  
  .close-btn, .enquiry-btn {
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .enquiry-btn {
    background-color: #4b53f2;
    color: white;
  }
  
  .service-detail-image {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 600px) {
    .service-detail-box {
        width: 242px;     
     }
     .service-box{
       margin: 15px 60px;
     }
  }