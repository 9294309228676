.product-section {
  padding: 50px 0;
  text-align: center;
  background: #1a1a21;
  height: 580px;
}
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .product-card {
    background: rgb(221, 250, 247);
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20%;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  .product-image {
    width: 100%; /* Make the image take the full width of the card */
    height: 100%; /* Maintain the aspect ratio */
    max-height: 200px; /* Set a maximum height for the image */
    object-fit: cover; /* Cover the entire area without stretching */
    display: block; /* Prevent any inline spacing issues */
  }
  
  .product-card:hover .product-image {
    transform: scale(1.1);
  }
  
  .product-name {
    padding: 15px;
    font-size: 1.2rem;
    color: #333;
  }
  /* Swiper Navigation Buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #fff; /* Change the color to match your design */
  width: 40px; /* Width of the buttons */
  height: 40px; /* Height of the buttons */
  display: flex; /* Use flexbox for centering the icon */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  position: absolute; /* Position relative to the Swiper container */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust to center */
  z-index: 10; /* Ensure the buttons are on top */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}