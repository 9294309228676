/* FAQ.css */
.faq-section {
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Use max-width instead of width */
    margin: 10% auto; /* Centering the section */
    padding: 20px; /* Add some padding for aesthetics */
    margin-left: 31%;
  }
  
  .faq-item {
    margin-bottom: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .faq-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-button {
    font-weight: bold;
    color: #343a40;
    transition: color 0.3s;
  }
  
  .accordion-button:hover {
    color: #007bff;
  }
  
  .accordion-body {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: inset 0px -2px 10px rgba(0, 0, 0, 0.05);
    padding: 15px;
    transition: max-height 0.3s ease;
  }
  
  /* Responsive styles */
  @media (max-width: 576px) {
    .faq-section {
        width: 90%; /* Adjust width for small screens */
        margin: 20% auto; /* Center the section */
    }
  }
  